define('ember-route-action/helpers/transition-to', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

  var Helper = _ember['default'].Helper;
  var assert = _ember['default'].assert;
  var computed = _ember['default'].computed;
  var getOwner = _ember['default'].getOwner;
  var get = _ember['default'].get;
  exports['default'] = Helper.extend({
    router: computed(function () {
      return getOwner(this).lookup('router:main');
    }).readOnly(),
    compute: function compute(_ref) {
      var _ref2 = _toArray(_ref);

      var routeName = _ref2[0];

      var params = _ref2.slice(1);

      var router = get(this, 'router');
      assert('[ember-route-action-helper] Unable to lookup router', router);
      return function () {
        for (var _len = arguments.length, invocationArgs = Array(_len), _key = 0; _key < _len; _key++) {
          invocationArgs[_key] = arguments[_key];
        }

        var args = params.concat(invocationArgs);
        var transitionArgs = params.length ? [routeName].concat(_toConsumableArray(params)) : [routeName];
        router.transitionTo.apply(router, transitionArgs);
        return args;
      };
    }
  });
});